import UserPreferences from "../../Components/UserPreferences/UserPreferences";

const UserPrefs = () => {
  return (
    <div className={`container`}>
      <UserPreferences/>
    </div>
  );
}

export default UserPrefs;