import styles from './History.module.scss';
import { useState } from "react";
import { pastQueryState, setHistory } from "../../Redux/historySlice";
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import Modal from "../../Components/Modals/Modal";
import Button from '../../Components/Core/Button';
import Warning from '../../Icons/Status/Alerts/Warning.svg?react';
import CloseIcon from '../../Icons/Buttons/Close/Close.svg?react';
import QueryHistoryList from "../../Components/QueryHistoryList/QueryHistoryList";
import LoginWarning from '../../Components/LoginWarning/LoginWarning';
import { useUser } from '../../Utilities/userApi';
import LoadingWrapper from '../../Components/LoadingWrapper/LoadingWrapper';

const History = () => {

  const dispatch = useDispatch();
  let queryHistoryState = useSelector(pastQueryState);
  const [modalOpen, setModalOpen] = useState(false);
  const [user, loading] = useUser();

  return (
    <LoadingWrapper loading={loading}>
      {
        !!user ?
          <div className={`container ${styles.historyInner}`}>
            <div className={styles.head}>
              <div className={styles.top}>
                <h2>Search History</h2>
                <Button 
                  handleClick={()=>setModalOpen(true)}
                  className={`${styles.button} ${styles.clearButton}`}
                  isSecondary
                  >
                  <CloseIcon/>
                  Clear All
                </Button>
              </div>
              <div className={styles.blurb}>
                <p>Results of questions you have asked are stored locally in your browser's history for up to 30 days. A shareable link to a result set can be generated by clicking the link icon to the left of each result.</p>
              </div>
            </div>
            <Modal 
              isOpen={modalOpen} 
              onClose={()=>setModalOpen(false)} 
              className={styles.modal} 
              containerClass={styles.modalContainer}
              hideCloseButton
              >
              <h6 className={styles.modalHeading}><Warning/>Warning!</h6>
              <p className={styles.warning}>This action cannot be undone.</p>
              <div className={styles.buttonContainer}>
                <Button 
                  className={styles.buttonOne}
                  handleClick={()=>{
                    dispatch(setHistory([])); 
                    setModalOpen(false);
                  }}
                  >
                  Clear History
                </Button>
                <Button 
                  className={styles.buttonTwo}
                  handleClick={()=>setModalOpen(false)}
                  >
                  Go Back
                </Button>
              </div>
            </Modal>
            {
              queryHistoryState.length > 0 &&
              <QueryHistoryList />
            }
            {
              queryHistoryState.length <= 0 && 
              <div className={`container ${styles.container} ${styles.noHistory}`}>
                <h6>No Search History Found!</h6>
                <p>The results of the questions you have asked are stored locally in your browser's history.</p>
                <div className={styles.buttonContainer}>
                  <Link to="/" className="primary button">Ask a Question</Link>
                </div>
              </div>
            }
          </div>
        :
          <div className={`container ${styles.container}`}>
            <LoginWarning text="Use the Log In link above in order to view and manage your search history."/>
          </div>
      }
    </LoadingWrapper>
  );
}

export default History;