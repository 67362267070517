import UserSaves from "../../Components/UserSaves/UserSaves";

const Workspace = () => {
  return (
    <div>
      <UserSaves/>
    </div>
  );
}

export default Workspace;