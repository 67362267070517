
const Four = () => {
  return (
    <div className={`container`}>
      <h2>404</h2>
      <p>This page does not exist.</p>
    </div>
  );
}

export default Four;